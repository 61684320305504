<template>
  <v-row>
    <v-col cols="12">
      <v-tabs v-model="tab">
        <v-tab>Share</v-tab>
        <v-tab>Settings</v-tab>
        <v-tab-item>
          <share-link></share-link>
        </v-tab-item>
        <v-tab-item>
          <setting-form
            :info="info"
            :loader="loader"
            from-app
            :app_id="app_id"
            :infoError="infoError"
            v-if="canManageApp"
          ></setting-form>
          <no-data
            v-else
            first-text="No settings to show"
            second-text="You don't have the permission to view and manage the app share settings page"
            permission-issues
          ></no-data>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import SettingForm from "@/view/components/App/SettingForm";
import ShareLink from "@/view/components/App/ShareLink";
export default {
  props: ["app_id", "loader", "info", "infoError"],
  components: {
    SettingForm,
    ShareLink,
  },
  TABS: {
    "app-share-link": 0,
    "app-share-settings": 1,
  },
  data() {
    return {
      tab: 0,
    };
  },
  watch: {
    tab(tab) {
      if (
        this.$route.name.includes(Object.keys(this.$options.TABS)[tab || 0])
      ) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.TABS)[tab || 0],
          params: { app_id: this.$route.params.app_id },
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message ||
              "Something went wrong, please reload your page and try again"
          );
        });
    },
  },
  created() {
    const activeTab = this.$route.name;
    this.tab = this.$options.TABS[activeTab] || 0;
  },
};
</script>

<style scoped>
</style>
