<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="pb-10 pr-10">
        <v-row>
          <v-col cols="12 pl-8 mt-4">
            <div class="titleFont subTitle">General</div>
          </v-col>
        </v-row>
        <v-row class="pl-10">
          <v-col cols="12">
            <screen-shot-upload
              :screenshot-list="info.screenshots"
            ></screen-shot-upload>
          </v-col>
        </v-row>
        <v-row class="pl-10">
          <v-col cols="12">
            <v-row align="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="form.categories"
                  :items="getCategoriesList"
                  filled
                  chips
                  class="mt-n4"
                  flat
                  label="App categories"
                  v-validate="'categories_validation'"
                  counter="3"
                  v-bind="veeValidate('category', '')"
                  item-text="name"
                  item-value="id"
                  multiple
                  persistent-hint
                  hint="In the future, apps can be submitted to be listed in the public app directory for the community to test and give feedback"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  v-validate="'max:600'"
                  v-bind="veeValidate('Description', '')"
                  rows="4"
                  counter="600"
                  label="Description"
                  filled
                  auto-grow
                  placeholder="Description about this app"
                  hint="This will be displayed in the public install page"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="form.settings.global_instructions"
              v-validate="'max:600'"
              v-bind="veeValidate('Instruction', '')"
              rows="4"
              counter="600"
              label="Global install instruction"
              filled
              auto-grow
              placeholder="Any special instructions for the testers to start testing this app?"
              hint="This will be displayed in the public install page. Ex: Demo account, environment configuration, tips,..."
            >
            </v-textarea>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <div>
                  App store links (Optional)
                  <VueTooltip
                    icon-color="grey"
                    color="primary"
                    text-class="white--text"
                    :v-html="'App store links will be displayed in the the public install page'"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  filled
                  v-model="form.settings.store_links.android"
                  v-validate="'url'"
                  class="mt-n5"
                  id="appstoreLink"
                  placeholder="https://play.google.com/store/apps/details?id=testapp.io"
                  v-bind="veeValidate('link', '')"
                  label="Android app store"
                  hint="Google Play store, Huawei AppGallery, Samsung Galaxy Store,..."
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.settings.store_links.ios"
                  v-validate="'url'"
                  class="mt-n5"
                  placeholder="https://apps.apple.com/us/app/testapp-io/id1518972541"
                  v-bind="veeValidate('Link', '')"
                  label="Apple app store"
                  filled
                  hint="The URL for the live app in Apple app store"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card outlined class="pb-10 pr-10">
        <v-row v-model="panel" multiple flat>
          <v-col cols="12 pl-8 mt-4">
            <div class="titleFont subTitle">Developer</div>
          </v-col>
          <v-row class="pl-10">
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.settings.developer_contact.name"
                    placeholder="Company name or your name"
                    class="mt-n5"
                    v-validate="'required|min:3|max:80'"
                    v-bind="veeValidate('Developer Name', '')"
                    filled
                    label="Developer Name"
                    hint="This name will be displayed in the the public install page"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.settings.developer_contact.email"
                    placeholder="Company email or your email"
                    class="mt-n5"
                    v-validate="'required|email'"
                    v-bind="veeValidate('Email', '')"
                    filled
                    label="Support Email"
                    hint="This email will be displayed in the the public install page"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.settings.developer_contact.privacy"
                    placeholder="Privacy Policy URL for this app"
                    class="mt-n5"
                    v-validate="'url'"
                    v-bind="veeValidate('Privacy', '')"
                    filled
                    label="Privacy Policy"
                    hint="Optional - Ex: https://company.com/privacy-policy"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.settings.developer_contact.terms"
                    placeholder="Terms & Conditions URL for this app"
                    class="mt-n5"
                    v-validate="'url'"
                    v-bind="veeValidate('Terms and condition', '')"
                    filled
                    label="Terms & Conditions"
                    hint="Optional - Ex: https://company.com/terms-conditions"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.settings.developer_contact.website"
                    placeholder="App or company website (Ex: https://testapp.io)"
                    v-validate="'url'"
                    class="mt-n5"
                    v-bind="veeValidate('Website', '')"
                    filled
                    label="Website"
                    hint="Optional - Ex: https://testapp.io"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-col>
    <!--    <v-col cols="12">-->
    <!--      <v-card outlined class="pb-10 pr-10">-->
    <!--        <v-row v-model="panel" multiple flat>-->
    <!--          <v-col cols="12 pl-8 mt-4">-->
    <!--            <div class="titleFont subTitle">Feedback</div>-->
    <!--          </v-col>-->
    <!--          <v-row class="pl-10">-->
    <!--            <v-col cols="6">-->
    <!--              <app-member-autocomplete-->
    <!--                v-model="selectedMember"-->
    <!--                multiple-->
    <!--              ></app-member-autocomplete>-->
    <!--            </v-col>-->
    <!--            <v-col cols="6">-->
    <!--              <v-checkbox label="Send feedback to Slack"></v-checkbox>-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-row>-->
    <!--      </v-card>-->
    <!--    </v-col>-->
    <v-col class="text-center" cols="12">
      <v-btn
        :loading="busy"
        color="primary"
        depressed
        large
        class="text-transform-none"
        :disabled="!canManageApp || errors.any() || busy"
        @click="updateSettings"
        >Update Settings</v-btn
      >
    </v-col>
    <image-upload
      v-if="appImageUpload"
      v-model="appImage"
      :enable-resize="true"
      title="Edit app icon"
      profile
      hide-overlay
      @close="appImageUpload = false"
      @cropped-image="onFileUpload"
    ></image-upload>
  </v-row>
</template>

<script>
import Vue from "vue";
import { get, isEmpty } from "@/core/services/helper.service";
import veeValidate from "@/mixins/veeValidate";
import {
  UPDATE_APP_SETTINGS,
  UPDATE_IMAGE,
  DELETE_IMAGE,
  GET_APP_CATEGORIES,
} from "@/store/apps/app.module";
import ImageUpload from "@/view/components/Common/ImageUpload";
import ScreenShotUpload from "@/view/components/App/ScreenShotUpload";
import { mapGetters, mapMutations } from "vuex";
import { Validator } from "vee-validate";
// import AppMemberAutocomplete from "@/view/components/Common/AppMemberAutocomplete";
Validator.extend("categories_validation", {
  getMessage: (field) => {
    return `App ${field} should be less or equal to 3 `;
  },
  // Returns a boolean value
  validate: (value) => {
    return value.length <= 3;
  },
});
export default {
  mixins: [veeValidate],
  props: ["app_id", "loader", "info", "infoError"],
  submitLoader: false,
  components: { ScreenShotUpload, ImageUpload },
  data() {
    return {
      get,
      busy: false,
      modal: false,
      loading: false,
      selectedMember: [],
      panel: [0, 1, 2, 3],
      appImage: "",
      appImageUpload: false,
      loadingConfirm: false,
      readMore: false,
      form: {
        categories: [],
        description: "",
        settings: {
          global_instructions: "",
          store_links: {
            android: "",
            ios: "",
          },
          developer_contact: {
            name: "",
            email: "",
            website: "",
            terms: "",
            privacy: "",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getTeamName: "getTeamName",
      getCategoriesList: "getCategoriesList",
    }),
  },
  mounted() {
    if (!this.loader) {
      if (isEmpty(this.getCategoriesList)) {
        this.getAppCategories();
      }
      this.populateInitialData();
      Vue.prototype.$analytics.logEvent("edit_app_start");
    }
  },
  methods: {
    ...mapMutations({
      updateAppInfo: "updateAppInfo",
    }),
    remove(item) {
      const index = this.form.categories.indexOf(item.id);
      if (index >= 0) this.form.categories.splice(index, 1);
      if (this.form.categories.length <= 3) {
        this.$validator.errors.clear();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.form[name];
      return $dirty ? !$error : null;
    },
    getAppCategories() {
      if (this.busy) return;
      this.busy = false;
      this.$store.dispatch(GET_APP_CATEGORIES).then(() => {
        this.busy = false;
      });
    },
    populateInitialData() {
      this.form = {
        categories: this.info.categories,
        description: this.info.description,
        settings: {
          global_instructions: this.info.settings.global_instructions || "",
          store_links: this.info.settings.store_links || {
            android: "",
            ios: "",
          },
          developer_contact: this.info.settings.developer_contact || {
            name: this.getTeamName,
            email: "",
            website: "",
            terms: "",
            privacy: "",
          },
        },
      };
    },
    async updateSettings() {
      if (await this.validateAllFields()) {
        if (this.errors.any() || this.busy) {
          this.busy = false;
          return false;
        }
        this.busy = true;
        const name = this.form.name;
        this.form.id = this.$route.params.app_id;

        this.$store
          .dispatch(UPDATE_APP_SETTINGS, this.form)
          .then((response) => {
            this.busy = false;
            this.onClose && this.onClose();
            this.updateAppInfo(this.form);
            this.$emit("success", { name });
            this.notifyUserMessage({
              message: response.message || "Successfully updated",
              timeout: true,
            });
            this.$emit("close");
            Vue.prototype.$analytics.logEvent("edit_app");
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message || "Update failed");
            Vue.prototype.$analytics.logEvent("edit_app_error");
            this.busy = false;
          });
      }
    },
    async onFileUpload(e) {
      Vue.prototype.$analytics.logEvent("app_image_start");

      if (this.loading) return;
      this.loading = true;
      const form = new FormData();
      form.append("image", e);
      try {
        const response = await this.$store.dispatch(UPDATE_IMAGE, {
          id: this.info.id,
          image: form,
        });
        this.notifyUserMessage(
          response.message || "App image successfully updated"
        );
        this.loading = false;
        Vue.prototype.$analytics.logEvent("app_image");
      } catch (err) {
        this.notifyErrorMessage(
          err.message || "Failed to update the app image"
        );
        this.loading = false;
        Vue.prototype.$analytics.logEvent("app_image_error");
      }
      this.loading = false;
    },
    onDeleteImage() {
      Vue.prototype.$analytics.logEvent("delete_app_image_start");
      this.$store
        .dispatch(DELETE_IMAGE, this.info.id)
        .then((response) => {
          this.notifyUserMessage(
            response.message || "Screenshot successfully deleted"
          );
          Vue.prototype.$analytics.logEvent("delete_app_image");
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || `Something went wrong, please try again`
          );
          Vue.prototype.$analytics.logEvent("delete_app_image_error");
        });
    },
  },
};
</script>

<style scoped></style>
