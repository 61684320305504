<template>
  <v-row class="">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="6">
          <div>
            Screenshots
            <VueTooltip
              icon-color="grey"
              color="primary"
              text-class="white--text"
              :v-html="
                'App screenshots will be displayed in the public install page. Check share page for more info. <br /><br />The actual image size can be found in the install page. We are going to enhance the screenshots, we welcome any feedfback!'
              "
            />
          </div>
          <div class="text-muted mb-8">
            Images will be resized to 250x500 pixels from the center
          </div>
        </v-col>
        <v-col class="text-right" cols="6">
          <v-btn
            @click="openUploadModal"
            :loading="loading"
            color="primary"
            class="text-transform-none"
          >
            <v-icon>mdi-image</v-icon>
            Upload screenshot
          </v-btn>
        </v-col>
        <v-col cols="12" class="" v-if="getAppScreenShotList.length">
          <v-row>
            <v-col cols="12">
              <div class="screenShotContainer" id="screenShotBox">
                <div
                  v-for="(item, index) in getAppScreenShotList"
                  :key="index"
                  style="display: flex"
                >
                  <v-hover v-slot="{ hover }">
                    <v-sheet class="imageBorder mr-2">
                      <v-img
                        style="border-radius: 20px"
                        :src="item.url"
                        width="250"
                        cover
                        height="500"
                      >
                        <v-icon
                          v-if="hover"
                          class="float-right ma-1"
                          color="danger"
                          @click="removeImage(item, index)"
                          v-text="'mdi-close'"
                        ></v-icon>
                        <v-chip
                          v-else
                          class="float-right ma-1"
                          color="primary"
                          small
                        >
                          {{ index + 1 }}
                        </v-chip>
                      </v-img>
                    </v-sheet>
                  </v-hover>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <Modal v-model="openRemoveImageModal" @close="openRemoveImageModal = false">
      <template #message>
        <v-row>
          <v-col class="mt-4 font-size-h3 bold-text" cols="12">
            Delete screenshot
          </v-col>
          <v-col class="bodyFont" cols="12">
            Are you sure you want to delete this image?
          </v-col>
          <v-col class="bodyFont" cols="12">
            <p>There's no undo for this action.</p>
          </v-col>
          <v-col class="text-right" cols="12">
            <v-btn
              text
              class="text-transform-none"
              color="primary"
              :disabled="loading"
              @click="openRemoveImageModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              depressed
              class="text-transform-none"
              color="primary"
              :loading="loading"
              @click="deleteImage"
            >
              Yes
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </Modal>
    <image-upload
      v-if="appImageUpload"
      v-model="appImage"
      title="Upload app screenshot"
      hide-overlay
      :view-port="{ width: 250, height: 500 }"
      @close="appImageUpload = false"
      @cropped-image="onFileSelected"
    ></image-upload>
  </v-row>
</template>

<script>
import {
  DELETE_APP_SCREENSHOT,
  GET_APP_SCREENSHOT,
  UPLOAD_APP_SCREENSHOT
} from "@/store/apps/app.module";
import { mapGetters, mapMutations } from "vuex";
import ImageUpload from "@/view/components/Common/ImageUpload";

export default {
  components: { ImageUpload },
  props: {
    screenshotList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      fileNames: [],
      totalFileSize: [],
      appImageUpload: false,
      appImage: "",
      screenshots: [],
      removeIndex: "",
      removeId: "",
      loading: false,
      openRemoveImageModal: false
    };
  },
  computed: {
    ...mapGetters({
      getAppScreenShotList: "getAppScreenShotList",
      appInfo: "getAppInfo"
    })
  },
  created() {
    this.getScreenshotList();
  },
  methods: {
    ...mapMutations({
      setAppScreenshot: "setAppScreenshot",
      removeAppScreenshot: "removeAppScreenshot"
    }),
    getScreenshotList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_APP_SCREENSHOT, { app_id: this.$route.params.app_id })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onFileSelected(event) {
      if (this.screenshots.length < 8) {
        if (this.loading) return;
        this.loading = true;
        if (event.size > 8000000) {
          this.loading = false;
          this.notifyErrorMessage("Image size should not exceed 8 MB");
          return;
        }
        let form = new FormData();
        form.append("image", event);
        this.$store
          .dispatch(UPLOAD_APP_SCREENSHOT, {
            app_id: this.$route.params.app_id,
            form: form
          })
          .then(response => {
            this.loading = false;
            this.setAppScreenshot({ id: response.id, url: response.url });
            this.$nextTick(() => {
              this.slideRight();
            });
          })
          .catch(err => {
            this.loading = false;
            this.notifyErrorMessage(err.message);
          });
      } else {
        this.notifyErrorMessage("Max of 8 screenshots in total for each app");
      }
    },
    removeImage(image, imageIndex) {
      this.openRemoveImageModal = true;
      this.removeIndex = imageIndex;
      this.removeId = image.id;
    },
    openUploadModal() {
      if(this.getAppScreenShotList.length >= 8) {
        this.notifyErrorMessage('You can only upload 8 screenshots')
      } else {
        this.appImageUpload = true
      }
    },
    slideRight() {
      let box = document.getElementById("screenShotBox");
      box.scrollLeft = box.scrollWidth;
    },
    deleteImage() {
      if (this.loading) return;
      this.loading = true;
      // this.attachments.splice(this.removeIndex, 1);
      this.totalFileSize.splice(this.removeIndex, 1);
      this.$store
        .dispatch(DELETE_APP_SCREENSHOT, {
          app_id: this.$route.params.app_id,
          screenshot_id: this.removeId
        })
        .then(response => {
          this.loading = false;
          this.openRemoveImageModal = false;
          this.removeAppScreenshot(this.removeIndex);
          this.notifyUserMessage(response.message);
        })
        .catch(err => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    }
  }
};
</script>

<style scoped>
.imageBorder {
  border-radius: 22px;
  margin-right: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
}
.dottedBoarder {
  border: 1px dashed #878787;
  box-sizing: border-box;
  border-radius: 16px;
}
.screenShotContainer {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  white-space: nowrap;
}
</style>
